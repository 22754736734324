<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3"
        ><v-icon color="primary" left>mdi-view-dashboard</v-icon>Dashboard
      </v-card-title>

      <v-divider></v-divider>
    </v-card>
    <v-row>
      <v-col
        cols="12"
        md="12"
        v-if="getAccess('vueDashboardNoQueEstamosTrabalhando', 'visualizar')"
      >
        <BoxAtividadesEmAndamento />
      </v-col>
      <v-col
        cols="12"
        md="12"
        v-if="getAccess('vueDashboardServicosEmAtraso', 'visualizar')"
      >
        <BoxAtividadesAtrasadas />
      </v-col>
      <v-col
        cols="12"
        md="12"
        v-if="
          getAccess('vueDashboardServicosAguardandoAprovacao', 'visualizar')
        "
      >
        <BoxAtividadesAguardandoAprovacao />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",

  components: {
    BoxAtividadesEmAndamento: () =>
      import("./components/BoxAtividadesEmAndamento.vue"),
    BoxAtividadesAtrasadas: () =>
      import("./components/BoxAtividadesAtrasadas.vue"),
    BoxAtividadesAguardandoAprovacao: () =>
      import("./components/BoxAtividadesAguardandoAprovacao.vue"),
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
  },

  methods: {},
};
</script>

<style lang="scss"></style>
